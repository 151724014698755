var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Navbar', {
    attrs: {
      "nav-light": true
    }
  }), _c('section', {
    staticClass: "bg-half bg-primary d-table w-100"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row justify-content-center"
  }, [_c('div', {
    staticClass: "col-lg-12 text-center"
  }, [_c('div', {
    staticClass: "page-next-level"
  }, [_c('h4', {
    staticClass: "title text-white",
    staticStyle: {
      "text-transform": "capitalize"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("search-list")) + " ")])])])])])]), _c('section', {
    staticClass: "section"
  }, [_c('div', {
    staticClass: "container card public-profile border-0 rounded shadow overflow-hidden",
    staticStyle: {
      "padding-right": "0px !important",
      "padding-left": "0px !important"
    }
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('h5', {
    staticClass: "text-center pb-4 uppercase"
  }, [_vm._v(" " + _vm._s(_vm.$t("wasiatasas.testator-list")) + " ")]), _c('div', {
    staticClass: "table-responsive bg-white shadow rounded mt-4"
  }, [_c('table', {
    staticClass: "table mb-0 table-center table-sm"
  }, [_c('thead', {
    staticClass: "bg-light"
  }, [_c('tr', [_c('th', [_vm._v(_vm._s(_vm.$t("index")))]), _c('th', [_vm._v(_vm._s(_vm.$t("amendment.no-wasiat")))]), _c('th', [_vm._v(_vm._s(_vm.$t("fullname")))]), _c('th', [_vm._v(_vm._s(_vm.$t("mykad")))]), _c('th', [_vm._v(_vm._s(_vm.$t("mobile-no")))]), _c('th', [_vm._v(_vm._s(_vm.$t("organization")))]), _c('th', [_vm._v(_vm._s(_vm.$t("agent")))]), _c('th', [_vm._v(_vm._s(_vm.$t("register-date")))]), _c('th', {
    staticClass: "text-right"
  }, [_vm._v(_vm._s(_vm.$t("action")))])])]), _c('tbody', _vm._l(_vm.wasiats, function (wasiat, index) {
    return _c('tr', {
      key: index
    }, [_c('td', [_vm._v(_vm._s(index))]), _c('td', [_vm._v(_vm._s(wasiat.wasiat_no))]), _c('td', [_vm._v(" " + _vm._s(wasiat.user_detail ? wasiat.user_detail.name : "-") + " ")]), _c('td', [_vm._v(" " + _vm._s(wasiat.user_detail ? wasiat.user_detail.ic_number : "-") + " ")]), _c('td', [_vm._v(" " + _vm._s(wasiat.user_detail ? wasiat.user_detail.mobile_number : "-") + " ")]), _c('td', [_vm._v(" " + _vm._s(wasiat.organization ? wasiat.organization.name : "-") + " ")]), _c('td', [_vm._v(" " + _vm._s(wasiat.agent ? wasiat.agent.name : "-") + " ")]), _c('td', [_vm._v(" " + _vm._s(_vm._f("formatDateTime")(wasiat ? wasiat.recorded_wasiat_date : "-")) + " ")]), _c('td', {
      staticClass: "text-right"
    }, [_c('router-link', {
      staticClass: "btn btn-secondary btn-sm",
      attrs: {
        "to": {
          name: 'carian.status-a',
          query: {
            user_id: wasiat.user_id
          }
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.$t("view")) + " ")])], 1)]);
  }), 0)])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-8"
  }, [_c('b-pagination', {
    attrs: {
      "total-rows": _vm.pagination.totalRows,
      "per-page": _vm.pagination.perPage,
      "aria-controls": "my-table"
    },
    on: {
      "change": _vm.pageClicks
    },
    model: {
      value: _vm.pagination.currentPage,
      callback: function ($$v) {
        _vm.$set(_vm.pagination, "currentPage", $$v);
      },
      expression: "pagination.currentPage"
    }
  })], 1), _c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "form custom-form"
  }, [_c('div', {
    staticClass: "form-group mb-0"
  }, [_c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.pagination.perPage,
      expression: "pagination.perPage"
    }],
    staticClass: "form-control custom-select",
    attrs: {
      "id": "Sortbylist-job"
    },
    on: {
      "change": [function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.pagination, "perPage", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }, function ($event) {
        return _vm.changePerPage();
      }]
    }
  }, [_c('option', {
    attrs: {
      "value": "10"
    }
  }, [_vm._v("10 " + _vm._s(_vm.$t("per-page")))]), _c('option', {
    attrs: {
      "value": "25"
    }
  }, [_vm._v("25 " + _vm._s(_vm.$t("per-page")))]), _c('option', {
    attrs: {
      "value": "50"
    }
  }, [_vm._v("50 " + _vm._s(_vm.$t("per-page")))])])])])])])])])])])])]), _c('Footer'), _c('a', {
    directives: [{
      name: "scroll-to",
      rawName: "v-scroll-to",
      value: '#topnav',
      expression: "'#topnav'"
    }],
    staticClass: "btn btn-icon btn-soft-primary back-to-top",
    attrs: {
      "href": "javascript: void(0);",
      "id": "back-to-top"
    }
  }, [_c('arrow-up-icon', {
    staticClass: "icons"
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }